@import "../00_base/variables";
@import "../00_base/mixins";

$cornerSize: 14px;

.a-background-title {
  color: $colorTitle;
  font-family: $fontRobotoCondensed;
  font-weight: 500;
  letter-spacing: -0.02em;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  padding: 0.3em 0.6em;
  position: relative;

  @include title-sizes();

  a {
    color: $colorTitle;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      color: $colorTitle;
      opacity: 0.8;
    }
  }

  &,
  &::before {
    background: $colorTitleBg;
  }

  &--small {
    margin-bottom: 0.3em;
    margin-top: 0.3em;
  }

  &--no-padding {
    padding-left: 0;
  }

  &--no-margin {
    margin-bottom: 0;
    margin-top: 0;
  }

  &--corner {
    clip-path: polygon(calc(100% - #{$cornerSize}) 0%, 100% #{$cornerSize}, 100% 100%, 0 100%, 0 0);
    padding-right: $cornerSize;
  }

  &--border {
    border-bottom: 2px solid $colorBorderTitle;
    border-top: 2px solid $colorBorderTitle;
  }

  &--light {
    background: $colorTitleAltBg;
    color: $colorTitleAlt;

    a { /* stylelint-disable-line no-descending-specificity */
      color: $colorLink;

      &:hover,
      &:active,
      &:focus {
        opacity: initial;
        text-decoration: underline;
      }
    }
  }
}

.a-background-title--border.a-background-title--corner {
  border-top: none;
}

.a-background-title--border.a-background-title--light {
  border-color: $colorBorderTitleAlt;
}
