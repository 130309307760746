@import "variables";

@mixin clearfix($before: false) {
  @if $before == true {
    &::before {
      clear: both;
      content: "";
      display: table;
    }
  }

  @else {
    &::after {
      clear: both;
      content: "";
      display: table;
    }
  }
}

@mixin title-sizes() {
  font-size: 1.6em;

  &--big {
    font-size: 2em;
  }

  &--small {
    font-size: 1.3em;
  }

  @media (min-width: $desktopBreakpoint) {
    font-size: 2.5em;

    &--big {
      font-size: 3em;
    }

    &--small {
      font-size: 1.5em;
    }
  }
}
